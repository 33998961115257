<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="mb-4 d-sm-flex align-items-center justify-content-start">
            <div class="me-sm-3">
                <img v-if="item.image" style="width:3rem;" :src="absoluteUrl(item.image)" />
                <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{item.model.charAt(0).toUpperCase()}}
                    </div>
                </div>
            </div> 
            <div class="">
                <p class="mb-1"><strong>Model:</strong> {{item.model}}</p>
                <div class="">
                    <span v-if="item.brand" class="me-3"> <strong>Make: </strong> {{item.brand.title}}</span>
                    <span v-if="item.category"> <strong>Category: </strong> {{item.category.title}}</span>
                </div>
            </div>
        </div>
        <div> 
            <p class="mb-1 small" v-if="item.annual_coverage"><strong>Yearly Coverage:</strong><br> {{item.annual_coverage.name}} - ${{item.annual_coverage.price}}</p>
            <p class="mb-0 small" v-if="item.monthly_coverage"><strong>Monthly Coverage:</strong><br> {{item.monthly_coverage.name}} - ${{item.monthly_coverage.price}}</p>
        </div>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>

</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    } 
}
</script>

<style>

</style>