
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Devices</h5>
      <div class="row mb-3 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultType" style="max-width:150px;" class="form-select flex-grow-1">
              <option v-for="category in categories" :key="category.id"
                        :value="category.id">{{category.title}}</option>
              <option :value="null"> View all</option>
            </select>
            <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-none d-lg-inline">Add device </span> </b-button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
				<p class="mb-0">Showing {{paginationInfo}} results</p>
			</div>
      <is-loading v-if="isLoading" />
      <div v-else class="row">
        <div class="col-xxl-4 col-sm-6"
          v-for="(device, dIndex) of pageData.data" :key="dIndex">
            <device-card :item="device">
              <template v-slot:actions>
                <router-link :to="`/devices/${device.id}`" class="btn me-3 btn-primary btn-sm">
                  <i class="ri ri-eye-line"></i></router-link>
                <a @click="deleteItem(device.id)" href="javascript:void(0);" class="btn btn-danger btn-sm">
                  <i class="ri ri-delete-bin-line"></i></a>
              </template>
            </device-card>
        </div>
      </div>
     
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal size="lg" v-model="popupModalShow" body-class="loading-viewport" hide-footer centered
        title="Add device"
        title-class="fs-6" @hide="initializeAll()">
        <device-form v-if="popupModalShow" @closeMe="initializeAll()"
        :editMode="editMode" :editItem="editItem" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import DeviceForm from "@/components/forms/DeviceForm.vue"
import DeviceCard from "@/components/cards/DeviceCard.vue"
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "device-list",
  components:{
    DeviceForm,DeviceCard,
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      defaultType: null,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100]
    }
  },
  computed: {
    pageData(){
      return this.$store.state.device.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    categories(){
      return this.$store.state.category.list
    },
    paginationInfo(){
			if(!this.currentPage) return '0';
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultType){ payload.category_id = this.defaultType }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("device/fetchDevices", payload)
      .then((_) => this.isLoading = false )
    },
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a device",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/devices/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("device/DELETE_DEVICE", itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.fetchItems()
    this.$store.dispatch("category/fetchItems")
  },
}
</script>

